import React, { useEffect, useState } from "react";
import "../css/App.css";
import "../css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CarMake from "../components/CarMake";
import CarMileage from "../components/CarMileage";
import CarModel from "../components/CarModel";
import CarVariant from "../components/CarVariant";
import CarYear from "../components/CarYear";
import apiAgent from "../api/apiAgent";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCar, FaUserAlt } from "react-icons/fa";
import { IoDocumentText, IoCloseSharp } from "react-icons/io5";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";

const App = () => {
  const [index, setIndex] = useState(1);
  const [masterIndex, setMasterIndex] = useState(1);
  const [carCompany, setcarCompany] = useState("");
  const [carModel, setcarModel] = useState("");
  const [carModelOptions, setcarModelOptions] = useState();
  const [carYear, setcarYear] = useState("");
  const [carYearOptions, setcarYearOptions] = useState();
  const [carVerient, setcarVerient] = useState("");
  const [carVerientOptions, setcarVerientOptions] = useState();
  const [carMileageValue, setcarMileageValue] = useState("");
  const [radioModels, setradioModels] = useState({
    insurance: "INSURANCE",
    refinance: "",
    sellcar: "",
    buycar: "",
  });
  const [termsCheck, setTermsCheck] = useState(false);
  const [model, setModel] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    make: carCompany,
    model: carModel,
    year: carYear,
    variant: carVerient,
    mileage: carMileageValue,
    idNumber: "",
    gender: "",
    language: "English",
    reasons: [],
    reference: "",
    domain: "",
  });
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [numberFocused, setNumberFocused] = useState(false);
  const { lookup, userTheme, reference } = apiAgent();
  const navigate = useNavigate();
  const [themeData, setThemeData] = useState(userTheme.clientInfo());
  const [isHovering, setIsHovering] = useState(false);
  const [show, setShow] = useState(false);
  const [tostMessagevalue, setTostMessagevalue] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-3RS012M6F9', { page_path: location.pathname });
  }, [location]);


  useEffect(() => {
    setThemeData(userTheme.clientInfo());
    setModel({ ...model, domain: userTheme.clientInfo().domain });
  }, []);
  useEffect(() => {
    carCompany &&
      lookup.model(carCompany).then((res) => {
        setcarModelOptions(res);
      });
  }, [carCompany]);
  useEffect(() => {
    if (carCompany && carModel) {
      lookup.year(carCompany, carModel).then((res) => {
        setcarYearOptions(res);
      });
    }
  }, [carCompany, carModel]);
  useEffect(() => {
    if (carCompany && carModel && carYear) {
      lookup.variant(carCompany, carModel, carYear).then((res) => {
        setcarVerientOptions(res);
      });
    }
  }, [carCompany, carModel, carYear]);

  useEffect(() => {
    if (!model.name) {
      setNameError("Please provide a valid Name.");
    } else if (model.name.length < 3) {
      setNameError("Name is Invalid");
    } else {
      setNameError("");
    }
  }, [model.name]);
  useEffect(() => {
    const regex = /^\d+$/;
    if (!model.phoneNumber) {
      setNumberError("Please provide a valid Number.");
    } else if (model.phoneNumber.length < 8) {
      setNumberError("PhoneNumber is Invalid");
    } else if (!regex.test(model.phoneNumber)) {
      setNumberError("PhoneNumber is Invalid");
    } else {
      setNumberError("");
    }
  }, [model.phoneNumber]);
  useEffect(() => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!model.emailAddress) {
      setEmailError("Please provide a valid Email.");
    } else if (!regex.test(model.emailAddress)) {
      setEmailError("EmailAddress is Invalid");
    } else {
      setEmailError("");
    }
  }, [model.emailAddress]);

  const onCarClose = () => {
    setIndex(1);
    setcarCompany("");
    setcarModel("");
    setcarYear("");
    setcarVerient("");
  };
  const onCarModelClose = () => {
    setIndex(2);
    setcarModel("");
    setcarYear("");
    setcarVerient("");
  };
  const onCarYearClose = () => {
    setIndex(3);
    setcarYear("");
    setcarVerient("");
  };
  const onCarVerientClose = () => {
    setIndex(4);
    setcarVerient("");
  };

  const onInputChange = (e) => {
    const { value, name } = e.target;
    setModel({ ...model, [name]: value });
  };

  const onRadioInputChange = (e) => {
    const { value, name } = e.target;
    if (value !== "No") {
      setradioModels({ ...radioModels, [name]: value });
    } else {
      setradioModels({ ...radioModels, [name]: "" });
    }
  };

  const onCheckBoxHandle = (event) => {
    setTermsCheck(event.target.checked);
  };

  const clearFun = () => {
    setIndex(1);
    setMasterIndex(1);
    setcarCompany("");
    setcarModel("");
    setcarModelOptions();
    setcarYear("");
    setcarYearOptions();
    setcarVerient("");
    setcarVerientOptions();
    setcarMileageValue(0);
    setradioModels({
      insurance: "INSURANCE",
      refinance: "",
      sellcar: "",
      buycar: "",
    });
    setTermsCheck();
    setModel({
      name: "",
      emailAddress: "",
      phoneNumber: "",
      make: carCompany,
      model: carModel,
      year: carYear,
      variant: carVerient,
      mileage: carMileageValue,
      idNumber: "",
      gender: "",
      language: "English",
      reasons: [],
      reference: "",
      domain: themeData.domain,
    });
    setNameError("");
    setEmailError("");
    setNumberError("");
    setNameFocused(false);
    setEmailFocused(false);
    setNumberFocused(false);
  };
  const tostMessage = (e) => {
    setTostMessagevalue(e);
    setShow(true);
  };

  const onReportClick = () => {
    if (!nameError && !numberError && !emailError) {
      if (termsCheck) {
        let bodyModel = model;
        bodyModel.make = carCompany;
        bodyModel.model = carModel;
        bodyModel.year = carYear;
        bodyModel.variant = carVerient;
        bodyModel.mileage = carMileageValue;
        let reasons = [
          radioModels.insurance,
          radioModels.refinance,
          radioModels.sellcar,
          radioModels.buycar,
        ];
        let newReason = reasons.filter((curElem) => {
          return curElem.length > 2;
        });
        bodyModel.reasons = newReason;
        bodyModel.domain = themeData.domain;

        lookup
          .register(bodyModel)
          .then((resp) => {
            navigate( "/reports?k=" + resp.response.reportkey + "&ref=" + reference());
            setTimeout(() => {
              clearFun();
            }, 3000);
          })
          .catch((err) => {
            window.scroll({
              top: 0,
              behavior: "smooth",
            });
            tostMessage("something went wrong please try again");
          });
      } else {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        tostMessage("Check terms and conditions");
      }
    } else {
      setNameFocused(true);
      setNumberFocused(true);
      setEmailFocused(true);
    }
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const style = {
    color: isHovering ? "white" : "white",
  };
  const radioStyle = {
    borderColor: themeData.theme_settings.primary_color,
    backgroundColor: themeData.theme_settings.primary_color,
  };

  return (
    <div className="app m-1 p-2">
      <Row style={{ position: "absolute", right: "10px" }}>
        <Col xs={12}>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={4000}
            autohide
          >
            <Toast.Header>
              <strong style={{ color: "red" }} className="me-auto">
                {tostMessagevalue}
              </strong>
            </Toast.Header>
            {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
          </Toast>
        </Col>
      </Row>
      <div className="row mt-1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="img_grid d-flex justify-content-center">
            <div className="logo_part">
              <img
                src={themeData.client_logo}
                alt="logo"
                id="logo"
                className="logo_img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="progress_bar">
            <div className="progress_bar_div">
              <div className="steps_lg">
                <div className="back_border"></div>
                <div
                  className={
                    masterIndex === 1 ? "step_lg bg_effect" : "step_lg"
                  }
                >
                  <div
                    className="step_lg_img mb-2"
                    style={{
                      background: themeData.theme_settings.primary_color,
                    }}
                  >
                    <FaCar />
                  </div>
                  <div className="step_lg_text">
                    <h3>Vehicle Details</h3>
                  </div>
                </div>
                <div
                  className={
                    masterIndex === 2 ? "step_lg bg_effect" : "step_lg"
                  }
                >
                  <div
                    className="step_lg_img mb-2"
                    style={{
                      background: themeData.theme_settings.primary_color,
                    }}
                  >
                    <FaUserAlt />
                  </div>
                  <div className="step_lg_text text_blur">
                    <h3>Personal Details</h3>
                  </div>
                </div>
                <div
                  className={
                    masterIndex === 3 ? "step_lg bg_effect" : "step_lg"
                  }
                >
                  <div
                    className="step_lg_img mb-2"
                    style={{
                      background: themeData.theme_settings.primary_color,
                    }}
                  >
                    <IoDocumentText />
                  </div>
                  <div className="step_lg_text text_blur">
                    <h3>Vehicle Report</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="progress_bar_sm">
            <div className="back_border_sm"></div>
            <div className="steps_sm">
              {masterIndex === 1 && (
                <>
                  <div className="step_sm ">
                    <div
                      className="step_sm_img mb-2"
                      style={{
                        background: themeData.theme_settings.primary_color,
                      }}
                    >
                      <FaCar />
                    </div>
                    <div className="step_sm_text">
                      <h3>Vehicle Details</h3>
                    </div>
                  </div>
                </>
              )}
              {masterIndex === 2 && (
                <>
                  <div className="step_sm ">
                    <div
                      className="step_sm_img mb-2"
                      style={{
                        background: themeData.theme_settings.primary_color,
                      }}
                    >
                      <FaUserAlt />
                    </div>
                    <div className="step_sm_text">
                      <h3>Personal Details</h3>
                    </div>
                  </div>
                </>
              )}
              {masterIndex === 3 && (
                <>
                  <div className="step_sm ">
                    <div
                      className="step_sm_img mb-2"
                      style={{ color: themeData.theme_settings.primary_color }}
                    >
                      <IoDocumentText />
                    </div>
                    <div className="step_sm_text">
                      <h3>Vehicle Report</h3>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {masterIndex === 1 && (
        <>
          <div className="functionality_top mt-5 container">
            <div className="row">
              <div className="col-md-6">
                <h2
                  className="heading_text"
                  style={{ color: themeData.theme_settings.secondary_color }}
                >
                  Let's start with your vehicle details
                </h2>
                {index === 1 && (
                  <h4 className="my-4 text_blur heading_text_sm">
                    Please select your vehicle make to continue
                  </h4>
                )}

                <div className="row">
                  {carCompany && (
                    <div className="col-md-12 my-3 trans_anim">
                      <div className="car_company_name">
                        <div>
                          <span className="make_btn">Make</span>
                          <span>
                            <strong>{carCompany}</strong>
                          </span>
                        </div>
                        <span onClick={() => onCarClose()}>
                          <IoCloseSharp
                            style={style}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {carModel && (
                    <div className="col-md-12 my-3 trans_anim">
                      <div className="car_company_name">
                        <div>
                          <span className="make_btn">Model</span>
                          <span>
                            <strong>{carModel}</strong>
                          </span>
                        </div>
                        <span onClick={() => onCarModelClose()}>
                          <IoCloseSharp
                            style={style}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {carYear && (
                    <div className="col-md-12 my-3 trans_anim">
                      <div className="car_company_name">
                        <div>
                          <span className="make_btn">Year</span>
                          <span>
                            <strong>{carYear}</strong>
                          </span>
                        </div>
                        <span onClick={() => onCarYearClose()}>
                          <IoCloseSharp
                            style={style}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {carVerient && (
                    <div className="col-md-12 my-3 trans_anim">
                      <div className="car_company_name">
                        <div>
                          <span className="make_btn">Variant</span>
                          <span>
                            <strong>{carVerient}</strong>
                          </span>
                        </div>
                        <span onClick={() => onCarVerientClose()}>
                          <IoCloseSharp
                            style={style}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {index === 1 && (
                  <CarMake
                    themeData={themeData}
                    setIndex={setIndex}
                    setcarCompany={setcarCompany}
                  />
                )}

                {index === 2 && (
                  <CarModel
                    themeData={themeData}
                    setIndex={setIndex}
                    setcarModel={setcarModel}
                    carModelOptions={carModelOptions}
                  />
                )}

                {index === 3 && (
                  <CarYear
                    themeData={themeData}
                    setIndex={setIndex}
                    setcarYear={setcarYear}
                    carYearOptions={carYearOptions}
                  />
                )}
                {index === 4 && (
                  <CarVariant
                    themeData={themeData}
                    setIndex={setIndex}
                    setcarVerient={setcarVerient}
                    carVerientOptions={carVerientOptions}
                  />
                )}
                {index === 5 && (
                  <CarMileage
                    themeData={themeData}
                    carMileageValue={carMileageValue}
                    setcarMileageValue={setcarMileageValue}
                    setMasterIndex={setMasterIndex}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {masterIndex === 2 && (
        <>
          <div className="functionality_top mt-5 container">
            <div className="row">
              <div className="col-md-6 trans_anim">
                <h2
                  className="heading_text"
                  style={{ color: themeData.theme_settings.secondary_color }}
                >
                  Enter your details so we can contact you
                </h2>
                <h4 className="my-4 text_blur heading_text_sm">
                  You are one step away from receiving your vehicle's value
                  report
                </h4>
              </div>
              <div className="col-md-6 trans_anim_rev">
                <div className="my-1 form_div">
                  <div className="">
                    <label className="text_blur form_lable">Full Name</label>
                    <input
                      type="text"
                      className="my-2 master_input"
                      name="name"
                      id=""
                      value={model.name}
                      onChange={(e) => onInputChange(e)}
                      onFocus={() => setNameFocused(true)}
                      onBlur={() => setNameFocused(false)}
                    />
                    {nameFocused && (
                      <span className="error_message">{nameError}</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="text_blur form_lable">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="my-2 master_input"
                      name="emailAddress"
                      id=""
                      value={model.emailAddress}
                      onChange={(e) => onInputChange(e)}
                      onFocus={() => setEmailFocused(true)}
                      onBlur={() => setEmailFocused(false)}
                    />
                    {emailFocused && (
                      <span className="error_message">{emailError}</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="text_blur form_lable">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      className="my-2 master_input"
                      name="phoneNumber"
                      id=""
                      value={model.phoneNumber}
                      onChange={(e) => onInputChange(e)}
                      onFocus={() => setNumberFocused(true)}
                      onBlur={() => setNumberFocused(false)}
                    />
                    {numberFocused && (
                      <span className="error_message">{numberError}</span>
                    )}
                  </div>
                  <div className="check_form mt-3">
                    <label className="text_blur form_lable">
                      Reason For Evaluation
                    </label>
                     
                    <div className="mt-3 d-flex align-items-center">
                      <span className="form_lable_span">Selling My Car</span>
                      <div className="radio_btns d-flex ">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="sellcar"
                            style={
                              radioModels.sellcar === "SELLCAR"
                                ? radioStyle
                                : { backgroundColor: "#fff" }
                            }
                            id=""
                            value={"SELLCAR"}
                            onChange={(e) => onRadioInputChange(e)}
                          />
                          <span className="mx-1 form_lable_radio">Yes</span>
                        </div>
                        <div className="d-flex mx-3">
                          <input
                            type="radio"
                            name="sellcar"
                            id=""
                            value={"No"}
                            style={
                              radioModels.sellcar !== "SELLCAR"
                                ? radioStyle
                                : { backgroundColor: "#fff" }
                            }
                            checked={
                              radioModels.sellcar !== "SELLCAR" ? true : false
                            }
                            onChange={(e) => onRadioInputChange(e)}
                          />
                          <span className="mx-1 form_lable_radio">No</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 d-flex align-items-center">
                      <span className="form_lable_span">Buying A Car</span>
                      <div className="radio_btns d-flex ">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="buycar"
                            style={
                              radioModels.buycar === "BUYCAR"
                                ? radioStyle
                                : { backgroundColor: "#fff" }
                            }
                            id=""
                            value={"BUYCAR"}
                            onChange={(e) => onRadioInputChange(e)}
                          />
                          <span className="mx-1 form_lable_radio">Yes</span>
                        </div>
                        <div className="d-flex mx-3">
                          <input
                            type="radio"
                            name="buycar"
                            id=""
                            value={"No"}
                            style={
                              radioModels.buycar !== "BUYCAR"
                                ? radioStyle
                                : { backgroundColor: "#fff" }
                            }
                            checked={
                              radioModels.buycar !== "BUYCAR" ? true : false
                            }
                            onChange={(e) => onRadioInputChange(e)}
                          />
                          <span className="mx-1 form_lable_radio">No</span>
                        </div>
                      </div>
                    </div>

                    <div className="terms_check mt-4 d-flex align-items-center">
                      <div className="check_box">
                        <input
                          type="checkbox"
                          name=""
                          id="terms_and_conditions"
                          checked={termsCheck}
                          onChange={(e) => onCheckBoxHandle(e)}
                        />
                        <span className="mx-2">I accept the</span>
                      </div>

                      <span
                        className="a_terms"
                        style={{
                          color: themeData.theme_settings.primary_color,
                        }}
                      >
                        terms and conditions
                      </span>
                    </div>
                  </div>

                  <div className="btns">
                    <button
                      className="next_btn mx-2 mt-4"
                      onClick={() => setMasterIndex(1)}
                    >
                      Back
                    </button>
                    <button
                      className="next_btn mx-2 mt-2"
                      onClick={() => onReportClick()}
                    >
                      Show Reports
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
