import React from "react";
import { IoMdCash } from "react-icons/io";
import { FaCarAlt } from "react-icons/fa";

const Pricing = ({ pricing, themeData,stats }) => {
  const priceFormat = (value) => {
    return "R "+ value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  return (
    <>
      <div className="price_details">
        <div className="d-flex justify-content-between">
          <h3 style={{ color: themeData.theme_settings.primary_color }}>
            Actual Pricing
          </h3>
          <IoMdCash style={{ color: themeData.theme_settings.primary_color }} />
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>New Price</h5>
          <span>{priceFormat(pricing.newPrice)}</span>
        </div>
        {/* <div className="d-flex justify-content-between my-3">
          <h5>Currency</h5>
          <span>{pricing.currency}</span>
        </div> */}
        <div className="d-flex justify-content-between my-3">
          <h5>Retail Price</h5>
          <span>{priceFormat(pricing.retailPrice)}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Trade Price</h5>
          <span>{priceFormat(pricing.tradePrice)}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Market Price</h5>
          <span>{priceFormat(pricing.marketPrice)}</span>
        </div>
      </div>
      {stats && stats.similar_vehicles_for_sales > 0 ?
        <div className="price_details">
          <div className="d-flex justify-content-between">
            <h3 style={{ color: themeData.theme_settings.primary_color }}>
              Cars Currently Advertised On The Web
            </h3>
            <FaCarAlt style={{ color: themeData.theme_settings.primary_color }} />
          </div>

          <div className="d-flex justify-content-between my-3">
            <h5>Similar Vehicles For Sale</h5>
            <span>{stats.similar_vehicles_for_sales}</span>
          </div>

          <div className="d-flex justify-content-between my-3">
            <h5>Average Price</h5>
            <span>{priceFormat(stats.avg_price)}</span>
          </div>
          <div className="d-flex justify-content-between my-3">
            <h5>Lowest Price</h5>
            <span>{priceFormat(stats.low_price)}</span>
          </div>
          <div className="d-flex justify-content-between my-3">
            <h5>Highest Price</h5>
            <span>{priceFormat(stats.high_price)}</span>
          </div>
          <div className="d-flex justify-content-between my-3">
            <h5>Most Popular Colour</h5>
            <span>{stats.most_popular_colour.charAt(0).toUpperCase() + stats.most_popular_colour.slice(1).toLowerCase()}</span>
          </div>
        </div>
        : null}
    </>
  );
};

export default Pricing;
